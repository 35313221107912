<!-- 竞拍大厅 -->
<template>
  <div class="bidHallBox">
    <!-- banner -->
    <div class="banner">
      <div class="bannerTit">
        <span>竞拍大厅</span>
      </div>
    </div>

    <div class="content">
      <!-- 已报名拍卖场次 -->
      <div class="registeredBox">
        <div class="title">已报名拍卖场次</div>
        <div class="tableBox">
          <el-table
            id="tableData"
            v-loading="tableLoading"
            :data="tableData"
            style="width: 100%"
            :header-cell-style="{
              background: '#F5F5F5',
              fontWeight: 600,
              fontSize: '14px',
              color: '#000000d9',
            }"
          >
            <el-table-column
              v-for="(item, index) in tableHeader"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :min-width="item.width ? item.width : 'auto'"
              align="left"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div v-if="item.prop == 'auctionStatusCopy'">
                  <el-tag
                    :type="scope.row.auctionStatus | getColorType"
                    size="small"
                    >{{ scope.row.auctionStatusCopy }}</el-tag
                  >
                </div>

                <div v-else-if="item.prop == 'startingPrice'">
                  <div
                    v-if="
                      scope.row.isPreQuotation == 'Y' &&
                        scope.row.auctionStatus == '0'
                    "
                  >
                    开拍前公布
                  </div>
                  <div v-else>
                    <span>{{ scope.row.startingPrice }}元</span>
                    <span v-if="scope.row.quoting == 1"
                      >/ {{ scope.row.quotationUnit }}</span
                    >
                  </div>
                </div>

                <div v-else-if="item.prop == 'transactionQuantity'">
                  <span class="pubCon"
                    >{{
                      scope.row.quoting == 1
                        ? scope.row.transactionQuantity
                        : "--"
                    }}{{ scope.row.quotationUnit }}</span
                  >
                </div>
                <div v-else>
                  {{ scope.row[item.prop] }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="left"
              width="150"
              fixed="right"
              prop="action"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleView(scope.row.auctionCode)"
                  >查看详情</el-button
                >
                <el-button
                  v-if="scope.row.auctionStatus == '0'"
                  type="text"
                  @click="hangleCancel(scope.row.auctionCode)"
                  >取消报名</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <div class="pagination">
            <el-pagination
              @current-change="handleCurrentChange()"
              :current-page.sync="queryParams.pageNum"
              :page-size.sync="queryParams.pageSize"
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- 正在拍卖 -->
      <div class="auctionProgress">
        <div class="title">
          {{
            bidData.auctionStatus == "0"
              ? "即将拍卖"
              : bidData.auctionStatus == "1"
              ? "正在拍卖"
              : "拍卖结束"
          }}
        </div>

        <div class="bidBox">
          <div class="left">
            <div class="img">
              <SwiperVueSlide :imgLIst="bidData.objectNames" />
            </div>

            <div class="steps">
              <div class="stepTitle">
                <span>当前拍卖阶段</span>
                <span
                  class="tip"
                  :title="
                    active == '0'
                      ? '在自由竞拍期间，参拍人员可在任何时间出价，出价不得低于当前最新价，涨幅不得低于最小加价幅。'
                      : active == '1'
                      ? `在延时竞拍期间，出价后${bidData.delayLength}分钟内无人再出价，则竞拍结束，最后出价人拍得标的。若出价后${bidData.delayLength}分钟内有其他用户出价，则剩余时间重新变为${bidData.delayLength}分钟倒计时，以此延续直至竞拍结束或进入下一阶段。`
                      : `时间进入限时竞拍阶段，剩余时间固定为${bidData.delayLength}分钟。在此期间用户可依据规则随时出价。最终时间结束前，最后出价成功者竞拍成功，竞拍结束。`
                  "
                  ><img
                    src="@/assets/images/bidHall/tip.png"
                  />了解竞拍规则</span
                >
              </div>
              <el-steps
                style="margin-top: 35px"
                process-status="finish"
                :active="active"
                finish-status="success"
                align-center
              >
                <el-step
                  title="自由竞拍"
                  :description="`${bidData.freeBiddingStage}分钟`"
                ></el-step>
                <el-step
                  title="延时竞拍"
                  :description="`${bidData.delayedBiddingStage}分钟`"
                ></el-step>
                <el-step
                  title="限时竞拍"
                  :description="`${bidData.delayLength}分钟`"
                ></el-step>
              </el-steps>

              <!-- <div style="margin-top: 10px">
                {{
                  active == "0"
                    ? "在自由竞拍期间，参拍人员可在任何时间出价，出价不得低于当前最新价，涨幅不得低于最小加价幅。"
                    : active == "1"
                    ? `在延时竞拍期间，出价后${bidData.delayLength}分钟内无人再出价，则竞拍结束，最后出价人拍得标的。若出价后${bidData.delayLength}分钟内有其他用户出价，则剩余时间重新变为${bidData.delayLength}分钟倒计时，以此延续直至竞拍结束或进入下一阶段。`
                    : `时间进入限时竞拍阶段，剩余时间固定为${bidData.delayLength}分钟。在此期间用户可依据规则随时出价。最终时间结束前，最后出价成功者竞拍成功，竞拍结束。`
                }}
              </div> -->
            </div>
          </div>
          <div class="right">
            <div class="statusAndTitle">
              <div
                :class="{
                  status1: bidData.auctionStatus == '0',
                  status: bidData.auctionStatus != '0',
                }"
              >
                {{ bidData.auctionStatus | seeStatus }}
              </div>
              <div class="tit">
                {{ bidData.auctionTitle }}
              </div>
            </div>

            <!-- 价格 -->
            <div class="price">
              <div class="enterpriseName">
                <span class="pubTit1">代表企业</span>
                <span class="pubCon">{{ bidData.manuName }}</span>
              </div>

              <div class="deposit">
                <div class="left1">
                  <span class="pubTit">保证金</span>
                  <span class="pubCon"
                    >{{ formatMoneyString(bidData.deposit) }}元</span
                  >
                </div>
                <div class="right1" v-if="bidData.isReservePrice == 'Y'">
                  <span class="pubTit">本场设置保留价</span>
                </div>
              </div>

              <div class="start">
                <div class="left1">
                  <span class="pubTit">起拍价</span>
                  <span
                    v-if="
                      bidData.auctionStatus == '0' &&
                        bidData.isPreQuotation == 'Y'
                    "
                    class="pubCon"
                    >开拍前公布</span
                  >
                  <span v-else class="pubCon"
                    >{{ formatMoneyString(bidData.startingPrice) }}元
                    <span v-if="bidData.quoting == 1"
                      >/ {{ bidData.quotationUnit }}
                    </span>
                  </span>
                </div>

                <div class="right1">
                  <span class="pubTit">加价幅</span>
                  <span
                    v-if="
                      bidData.auctionStatus == '0' &&
                        bidData.isPreQuotation == 'Y'
                    "
                    class="pubCon"
                    >开拍前公布</span
                  >
                  <span v-else class="pubCon"
                    >{{ formatMoneyString(bidData.minimumMarkup) }}元
                    <span v-if="bidData.quoting == 1"
                      >/ {{ bidData.quotationUnit }}
                    </span>
                  </span>
                </div>
              </div>

              <div class="evaluationPrice">
                <div class="left1">
                  <span class="pubTit">交易量</span>
                  <span class="pubCon"
                    >{{
                      bidData.quoting == 1 ? bidData.transactionQuantity : "--"
                    }}{{ bidData.quotationUnit }}</span
                  >
                </div>

                <div class="right1">
                  <span class="pubTit1">竞价周期</span>
                  <span class="pubCon">{{ bidData.freeBiddingStage }}分钟</span>
                </div>
              </div>
              <div class="evaluationPrice">
                <div class="left1">
                  <span class="pubTit">评估价</span>
                  <span
                    v-if="
                      bidData.auctionStatus == '0' &&
                        bidData.isPreQuotation == 'Y'
                    "
                    style="font-size: 14px"
                  >
                    开拍前公布
                  </span>
                  <span v-else class="pubCon"
                    >{{ formatMoneyString(bidData.marketValuation) }}

                    <span v-if="bidData.quoting == 1 && bidData.marketValuation"
                      >元 / {{ bidData.quotationUnit }}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <!-- 倒计时 -->
            <div class="countdown">
              <div class="end">
                <span class="pubTit">{{
                  bidData.auctionStatus == "0" || bidData.auctionStatus == "4"
                    ? "距开始"
                    : "距结束"
                }}</span>
                <span class="time1">
                  <span class="time">{{ formatTime(day) }}</span> 天
                  <span class="time">{{ formatTime(hour) }}</span> 时
                  <span class="time">{{ formatTime(minutes) }}</span> 分
                  <span class="time">{{ formattedSeconds }}</span>
                  秒</span
                >
              </div>

              <div class="currentPrice">
                <div class="priceBox">
                  <span class="pubTit">当前价</span>
                  <span
                    v-if="
                      bidData.auctionStatus == '0' &&
                        bidData.isPreQuotation == 'Y'
                    "
                    class="price2"
                    >开拍前公布</span
                  >
                  <span class="price2" v-else>
                    <span class="price1">
                      {{ formatMoneyString(currentInfo.newPrice) || "---" }}
                      <span v-if="bidData.quoting == 1"
                        >元 / {{ bidData.quotationUnit }}
                      </span>
                    </span>
                    <!-- 0:未达保留价  1:即将到达保留价 2:已打保留价 -->
                    <span
                      v-if="bidData.isReservePrice == 'Y'"
                      style="color: red"
                      >{{
                        currentInfo.reservePriceToken | reservePriceStatus
                      }}</span
                    >
                  </span>
                </div>

                <div class="personBox">
                  <span class="pubTit">出价人</span>
                  <span class="num">{{ currentInfo.userId || "---" }}</span>
                </div>
              </div>
            </div>

            <!-- 出价、观看、失败、成功、结束 -->
            <!-- 通过出价人是否有值判断是否是第一次出价，第一次出价为起拍价 -->
            <!-- 同企业本人正在拍卖 -->
            <div
              class="offer offerPrice"
              v-if="application == '2' && bidData.auctionStatus == '1'"
            >
              <div class="top">
                <div class="my">
                  <span class="wz">我的出价</span>
                  <span class="price3"
                    >￥<span class="price4">{{
                      formatMoneyString(offerData.newPrice) || "---"
                    }}</span></span
                  >
                </div>

                <div class="bidNumber">
                  <span class="wz">我的出价号</span>
                  <span class="bidNum">{{ offerData.userId || "---" }}</span>
                </div>
              </div>

              <div class="btnClass">
                <div class="priceBtn">
                  <el-radio-group
                    size="small"
                    v-model="checkboxGroup1"
                    @change="handlePrice()"
                  >
                    <el-radio-button
                      :disabled="radioDisabled"
                      v-for="item in priceOptions"
                      :label="item"
                      :key="item"
                      >+{{ item }}</el-radio-button
                    >
                  </el-radio-group>
                </div>
                <div class="btn">
                  <el-button type="success" @click="confirmOffer()"
                    >确认出价</el-button
                  >
                </div>
              </div>
            </div>

            <!-- 即将开始 -->
            <div class="offer" v-if="bidData.auctionStatus == '0'">
              <div class="hs">
                <span>竞拍即将开始，请耐心等待...</span>
              </div>
            </div>

            <!-- 同企业其他人看到的页面 -->
            <div
              class="offer"
              v-if="application == '1' && bidData.auctionStatus == '1'"
            >
              <div class="top">
                <span class="wz">企业出价号</span>
                <span class="bidNum">{{ offerData.userId }}</span>
              </div>

              <div class="top">
                <span class="wz">竞拍代理人</span>
                <span class="bidNum">{{ bidData.realName }}</span>
              </div>
            </div>

            <!-- 拍卖结束正在核算 -->
            <div
              class="offer"
              v-if="bidData.auctionStatus == '2' && bidStatus == '0'"
            >
              <div class="hs">
                <span>拍卖已成交</span>
              </div>
            </div>

            <!-- 成功 -->
            <div class="offer1" v-if="bidStatus == '1'">
              <div class="success">恭喜您！竞拍成功！</div>
              <div class="tip">
                请前往工作台，尽快完成合同签署等后续工作。完成后保证金将解冻释放至企业余额中。
              </div>
            </div>

            <!-- 失败 -->
            <div class="offer" v-if="bidStatus == '2'">
              <div class="fail">很遗憾，竞拍失败</div>
              <div class="tipFail">
                保证金将会解冻至您的钱包余额中，请留意查看。
              </div>
            </div>

            <!-- 流拍 -->
            <div
              class="offer"
              v-if="bidData.auctionStatus == '3'"
              style="position: relative"
            >
              <div class="hs">拍卖已流拍</div>
              <div
                v-if="bidData.isReservePrice == 'Y'"
                style="
                  position: absolute;
                  left: 50%;
                  transform: translateX(-50%);
                  bottom: 20px;
                  color: rgba(0, 0, 0, 0.4);
                "
              >
                出价未达到卖方保留价
              </div>
            </div>

            <!-- 取消 -->
            <div class="offer" v-if="bidData.auctionStatus == '4'">
              <div class="hs">拍卖已取消</div>
            </div>

            <!-- 中止 -->
            <div class="offer" v-if="bidData.auctionStatus == '5'">
              <div class="hs">拍卖已中止</div>
            </div>

            <!-- 操作记录 -->
            <div class="operationRecord">
              <div class="recordTit">操作记录</div>
              <div class="recordCon">
                <div class="conList">
                  <div
                    class="list"
                    v-for="(item, index) in bidRecord"
                    :key="index"
                  >
                    <span>{{ item }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperVueSlide from "@/pages/biddingProject/component/swiperVueSlide.vue";
import Bulletin from "@/pages/biddingProject/component/bulletin.vue";
import { haveBidder, hallInfo, queryBidRecord } from "@/api/bidHall";
import { getAuctionList, cancelApplication } from "@/api/workSpace";
// import WebSocket from 'websocket';
import { mapState } from "vuex";
// import { formatMoneyString } from '@/utils/publicFunction';
export default {
  components: {
    SwiperVueSlide,
    Bulletin,
  },
  filters: {
    //tag类型
    getColorType(colorId) {
      const colorMap = {
        0: "success",
        1: "warning",
        2: "info",
        3: "info",
        4: "info",
        5: "info",
        6: "info",
        7: "info",
        8: "info",
      };
      return colorMap[colorId];
    },

    seeStatus(auctionStatus) {
      const statusMap = {
        0: "即将开始",
        1: "正在拍卖",
        2: "已成交",
        3: "已流拍",
        4: "已取消",
        5: "已中止",
        6: "已支付",
        7: "签署完成",
        8: "签署失败",
      };
      return statusMap[auctionStatus];
    },

    reservePriceStatus(status) {
      const reservePrice = {
        0: "未达保留价",
        1: "即将到达保留价",
        2: "已达保留价",
      };
      return reservePrice[status] || "未达保留价";
    },
  },
  data() {
    return {
      loading: false,
      application: "", //application  报名状态    0=未报名  1=同企业非本人报名  2=本人报名
      bidData: {}, //起拍价，评估价等信息
      radioDisabled: true,
      tableData: [],
      title: "",
      // bidRecordData: [],
      // currentTime: new Date().getTime(),
      intervalId: null,
      socket: null,
      targetTime: null,
      day: 0,
      hour: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
      status: "1",
      activeName: "first",
      releasedData: {},
      priceOptions: [],
      checkboxGroup1: "",
      active: 0,
      total: 0,
      tableLoading: false,
      listData: [],
      queryParams: {
        hall: "hall",
        pageNum: 1,
        pageSize: 5,
      },
      // countdown: 0,
      tableHeader: [
        {
          label: "项目编号",
          prop: "auctionCode",
          width: 100,
        },
        {
          label: "竞拍标的",
          prop: "auctionTitle",
          width: 200,
        },
        {
          label: "开拍时间",
          prop: "dateDayStartTime",
          width: 140,
        },
        {
          label: "起拍金额",
          prop: "startingPrice",
          width: 120,
        },
        {
          label: "交易数量",
          prop: "transactionQuantity",
          width: 90,
        },
        {
          label: "出拍企业",
          prop: "applyForBusiness",
          width: 200,
        },
        {
          label: "当前状态",
          prop: "auctionStatusCopy",
          width: 90,
        },
        {
          label: "中标信息",
          prop: "bidNum",
          width: 100,
        },
      ],
      bidStatus: "0", //1成功  2失败
      offerData: {
        userId: "",
        newPrice: "",
        auctionCode: "",
      }, //点击确认出价所传的值

      currentInfo: {
        userId: "",
        startEndTime: null,
      }, //当前出价信息
      bidRecord: [],
      timer: null,
      executed: false, // 添加一个标志位
    };
  },
  watch: {
    // 监听出价人是否有值，没值代表没人出过价禁用加价幅，
    "currentInfo.userId": {
      handler(newVal) {
        if (newVal) {
          this.radioDisabled = false;
          // this.checkboxGroup1 = this.priceOptions[0];
        }
      },
      deep: true,
      immediate: true,
    },
    "bidData.auctionStatus": {
      handler(newVal) {
        if (newVal != "0" && newVal != "1") {
          console.log("状态已更新");
          if (this.socket) {
            // console.log("连接存在");
            // this.socket.onclose = function() {
            this.socket.close(1000, this.bidData.auctionCode);
            console.log("WebSocket 连接已关闭");
            // };
          }
          this.active = 3;
          clearInterval(this.intervalId);
        }
        // 监听项目状态，当项目从即将开始变成正在拍卖后更新依稀页面的数据---因为后加的预报价功能需要收集大家的预报价作为起拍价，然后去管理端配置，当项目状态变成正在拍卖的时候肯定是最新的
        if (newVal == "1" && !this.executed) {
          this.getDetail();
          this.executed = true; // 执行完后，将标志位设为true，防止重复执行
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    // 计算属性用于处理显示的秒数
    formattedSeconds() {
      return this.seconds + "." + this.formatMilliseconds(this.milliseconds);
    },
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    // 解析传递的 JSON 字符串
    const params = JSON.parse(this.$route.query.params);
    // this.offerData.userId = this.userInfo.userId;
    this.offerData.auctionCode = params.auctionCode;
    this.application = params.application;
    // this.init();
    this.getAuctionList();
    this.getDetail();

    // 在组件挂载后，每隔一秒检查当前时间是否小于目标时间
  },
  beforeDestroy() {
    if (this.socket) {
      // console.log("连接存在");
      this.socket.close(1000, this.bidData.auctionCode);
      console.log("WebSocket 连接已关闭");
    }
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    clearInterval(this.intervalId);
  },
  methods: {
    handleBeforeUnload() {
      // 在页面刷新前执行一些操作
      // 注意：这里的代码可能不会完全执行，取决于浏览器和网络条件
      if (this.socket) {
        this.socket.close(1000, this.bidData.auctionCode);
        console.log("页面即将刷新,断开连接");
      }
    },
    checkCurrentTime() {
      const currentTime = new Date().getTime();
      // console.log(new Date());
      // console.log(new Date(this.bidData.startTime));
      // console.log(new Date(this.bidData.endTime));
      // console.log(new Date(this.bidData.lastTime));
      // console.log(new Date(this.bidData.lastEndTime));
      // if (this.bidData.auctionStatus == "1") {
      // if (this.bidData.endTime) {
      if (currentTime < new Date(this.bidData.startTime).getTime()) {
        this.active = -1;
      } else if (
        currentTime < new Date(this.bidData.endTime).getTime() &&
        currentTime > new Date(this.bidData.startTime).getTime()
      ) {
        // 当前时间小于自由竞价结束时间，自由竞价阶段亮
        this.active = 0;
      } else if (
        currentTime > new Date(this.bidData.endTime).getTime() &&
        currentTime < new Date(this.bidData.lastTime).getTime()
      ) {
        // 当前时间大于自由竞价结束时间，小于显示竞价开始时间，延时竞价阶段亮
        this.active = 1;
      } else if (
        currentTime > new Date(this.bidData.lastTime).getTime() &&
        currentTime < new Date(this.bidData.lastEndTime).getTime()
      ) {
        // 当前时间大于限时竞价阶段开始时间，小于最终结束时间，限时竞价阶段亮
        this.active = 2;
      } else if (currentTime > new Date(this.bidData.lastEndTime).getTime()) {
        // 当前时间大于最终结束时间，限时竞拍结束
        this.active = 3;
      }
      // }
      // } else {
      //   this.active = 0;
      // }
    },
    // 取消报名
    hangleCancel(data) {
      this.$confirm(
        "您确定要取消竞拍报名吗？取消报名后，已冻结的保证金将解冻并存入企业钱包当中。",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        cancelApplication({ auctionCode: data }).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            if (data == this.offerData.auctionCode) {
              this.$router.go(-1);
            } else {
              this.getAuctionList();
            }
          }
        });
      });
    },
    // 已报名拍卖场次
    getAuctionList() {
      this.tableLoading = true;
      getAuctionList(this.queryParams).then((res) => {
        this.tableData = res.rows.map((item) => {
          item.dateDayStartTime = item.dateDay + " " + item.auctionStartTime;
          item.startingPrice = this.formatMoneyString(item.startingPrice);
          if (item.auctionStatus == "0") {
            item.auctionStatusCopy = "即将开始";
          } else if (item.auctionStatus == "1") {
            item.auctionStatusCopy = "正在拍卖";
          } else if (item.auctionStatus == "2") {
            item.auctionStatusCopy = "已成交";
          } else if (item.auctionStatus == "3") {
            item.auctionStatusCopy = "已流拍";
          } else if (item.auctionStatus == "4") {
            item.auctionStatusCopy = "已取消";
          } else if (item.auctionStatus == "5") {
            item.auctionStatusCopy = "已中止";
          } else if (item.auctionStatus == "6") {
            item.auctionStatusCopy = "已支付";
          } else {
            item.auctionStatusCopy = "合同签署完成";
          }
          return item;
        });
        // console.log(this.tableData);

        this.total = res.total;
        this.tableLoading = false;
      });
    },

    handleClick(tab, event) {
      if (tab.name == "fourth") {
        this.getRecordData();
      }
    },

    // 初始化，判断是否有人出价过
    init() {
      haveBidder({ auctionCode: this.offerData.auctionCode }).then(
        ({ data }) => {
          // wss://dianchipaimai.com:8086  //正式环境
          // ws://10.11.9.23:8086  //测试环境
          // ws://192.168.66.6:8086  本地
          let wsUrl = process.env.VUE_APP_WS_URL;
          console.log(wsUrl);
          if (data) {
            let params = JSON.parse(this.$route.query.params);
            // true代表没人出价过，把起拍价传过去
            this.socket = new WebSocket(
              `${wsUrl}?userId=` +
                this.offerData.userId +
                "&auctionCode=" +
                this.offerData.auctionCode +
                "&startingPrice=" +
                params.startingPrice
            );
          } else {
            // console.log(false);
            this.socket = new WebSocket(
              `${wsUrl}?userId=` +
                this.offerData.userId +
                "&auctionCode=" +
                this.offerData.auctionCode
            );
          }
          // if (
          //   this.bidData.auctionStatus == "0" ||
          //   this.bidData.auctionStatus == "1"
          // ) {
          // 项目状态未开始和正在进行中才可以建立连接
          this.socket.onopen = () => {
            console.log("WebSocket 连接已建立");
          };

          // } else {
          //   console.log("项目已结束");
          // }

          this.socket.onmessage = (event) => {
            console.log(event);
            //  清除定时器
            if (this.timer) {
              clearInterval(this.timer);
            }
            this.currentInfo = JSON.parse(event.data);

            // // 同步最新价格到我的出价，我的出价=当前价格+当前选择加价幅
            if (this.currentInfo.userId) {
              this.offerData.newPrice =
                this.currentInfo.newPrice + Number(this.checkboxGroup1);
            } else {
              // 因为预报价功能起拍价格不确定，当没有人出价时，把接口返回的起拍价的值赋值给当前价格和我的出价
              this.currentInfo.newPrice = this.bidData.startingPrice;
              this.offerData.newPrice = this.bidData.startingPrice;
            }

            // 后端竞拍完成之后不给发操作记录，此时前端判断是否有竞价记录，没有就不渲染，用上一次的
            if (this.currentInfo.bidRecord) {
              this.bidRecord = JSON.parse(this.currentInfo.bidRecord);
            }

            // 如果startEndTime等于0则表示出价失败，后端倒计时不给返回时间，前端不渲染
            if (this.currentInfo.startEndTime != 0) {
              // this.countdown = this.currentInfo.startEndTime;
              this.targetTime =
                new Date().getTime() + this.currentInfo.startEndTime;
            }

            this.timer = setInterval(this.updateCountdown, 10); // 启动定时器
            // 判断startToken==‘1’状态为正在拍卖
            if (this.currentInfo.startToken) {
              // 拍卖完成根据返回的userId判断展示成功或者失败
              if (this.currentInfo.startToken == "2") {
                if (this.currentInfo.userId == this.bidData.bidNum) {
                  this.bidStatus = "1";
                } else {
                  this.bidStatus = "2";
                }
              } else if (
                this.currentInfo.startToken == "4" ||
                this.currentInfo.startToken == "5"
              ) {
                // 如果拍卖开始之前进入大厅，后台在开拍之前或者拍卖中取消或者中止项目，倒计时停止
                clearInterval(this.timer);
              }
              this.bidData.auctionStatus = this.currentInfo.startToken;
            }
          };
        }
      );
    },

    // 获取详情信息
    getDetail() {
      hallInfo({ auctionCode: this.offerData.auctionCode }).then((res) => {
        this.bidData = res.data;
        // 后端出价号是userId，把bidNum赋值给userId;
        this.offerData.userId = this.bidData.bidNum;

        // Check data types and convert if necessary
        this.minimumMarkup = Number(this.bidData.minimumMarkup);
        if (isNaN(this.minimumMarkup)) {
          console.error("Minimum markup is not a valid number.");
          return;
        }
        // 把最小加价幅push进数组
        this.bidData.markupMultiple.push("1");
        this.priceOptions = this.bidData.markupMultiple.map((item) => {
          const itemValue = Number(item);

          // Check if itemValue is a valid number
          if (isNaN(itemValue)) {
            console.error(`Invalid number in markupMultiple: ${item}`);
            return 0; // or handle the error in an appropriate way
          }

          const result = this.minimumMarkup * itemValue;
          // console.log(result);
          return result.toFixed(0);
        });

        // 排序
        this.priceOptions.sort(function(a, b) {
          return a - b;
        });
        this.checkboxGroup1 = this.priceOptions[0];

        let startTime = new Date(
          this.bidData.dateDay + " " + this.bidData.auctionStartTime
        );

        let endTime = new Date(startTime);
        endTime.setMinutes(
          startTime.getMinutes() +
            Number(this.bidData.freeBiddingStage) -
            Number(this.bidData.delayLength)
        );

        let lastTime = new Date(startTime);
        lastTime.setMinutes(
          startTime.getMinutes() +
            Number(this.bidData.maximumAuctionDuration) -
            Number(this.bidData.delayLength)
        );

        let lastEndTime = new Date(startTime);
        lastEndTime.setMinutes(
          startTime.getMinutes() + Number(this.bidData.maximumAuctionDuration)
        );

        this.bidData.startTime = startTime;
        // console.log("当前时间", new Date());
        // console.log("自由竞价开始时间", this.bidData.startTime);

        this.bidData.endTime = endTime;
        // console.log("延时竞拍开始时间", this.bidData.endTime);

        this.bidData.lastTime = lastTime;
        // console.log("限时竞价开始时间", this.bidData.lastTime);

        this.bidData.lastEndTime = lastEndTime;
        // console.log("最终结束时间", this.bidData.lastEndTime);

        this.intervalId = setInterval(() => {
          this.checkCurrentTime();
        }, 1000);
        if (
          this.bidData.auctionStatus == "0" ||
          this.bidData.auctionStatus == "1"
        ) {
          this.init();
        }
      });
    },

    // 加价幅change
    handlePrice() {
      this.offerData.newPrice =
        this.currentInfo.newPrice + Number(this.checkboxGroup1);
    },
    // 确认出价
    confirmOffer() {
      this.socket.send(JSON.stringify(this.offerData));
    },
    // 倒计时
    updateCountdown() {
      // console.log(111);
      if (!this.currentInfo || this.currentInfo.startEndTime === undefined) {
        return; // 避免在未初始化时执行
      }
      const currentTime = new Date().getTime();
      let timeDifference = this.targetTime - currentTime;
      // console.log(timeDifference);
      // 如果剩余时间大于0，继续倒计时
      if (timeDifference > 0) {
        this.day = Math.floor(timeDifference / (24 * 60 * 60 * 1000));

        this.hour = Math.floor(
          (timeDifference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
        );

        this.minutes = Math.floor(
          (timeDifference % (60 * 60 * 1000)) / (60 * 1000)
        );
        // console.log(this.minutes);
        this.seconds = Math.floor((timeDifference % (60 * 1000)) / 1000);
        this.milliseconds = timeDifference % 1000;
      } else {
        // 如果倒计时结束，清空所有值
        this.day = this.hour = this.minutes = this.seconds = this.milliseconds = 0;
        // this.hour = this.minutes = this.seconds = this.milliseconds = 0;
        // 清除定时器
        clearInterval(this.timer);
      }
    },
    formatTime(time) {
      return time < 10 ? `0${time}` : time;
    },
    formatMilliseconds(milliseconds) {
      return milliseconds;
    },
    // 倒计时结束

    handleView(data) {
      // 打开一个新窗口并跳转路由
      const newWindow = window.open("", "_blank");

      // 设置新窗口的路由信息
      if (newWindow) {
        newWindow.location.href = `#/biddingProject/details?auctionCode=${data}`;
      }
      // this.$router.push({
      //   path: "biddingProject/details",
      //   query: {
      //     auctionCode: data,
      //   },
      // });
    },

    // 当前页
    handleCurrentChange(val) {
      this.getAuctionList();
    },

    getRecordData() {
      let params = JSON.parse(this.$route.query.params);
      queryBidRecord({
        auctionCode: params.auctionCode,
      }).then((res) => {});
    },
  },
};
</script>

<style lang="less" scoped>
// /deep/ .el-table-horizontal-scrollbar:hover {
//   transform: scaleY(1.5);
//   filter: brightness(0.1);
//   transform: scaleY(1.75) translateY(-10%);
// }
/deep/.el-step__description {
  font-size: 16px;
}
.el-table td.el-table__cell div {
  color: rgba(0, 0, 0, 0.85);
}
.el-button--success {
  background-color: #0dac65;
}
.el-step.is-simple /deep/ .el-step__title {
  width: 190px;
  white-space: pre-line;
}
.el-table td.el-table__cell div {
  overflow: hidden;
  text-overflow: ellipsis;
}
.el-tag.el-tag--success {
  background-color: #e8f6ef;
  color: #0dac65;
}
.pagination {
  width: 100%;
  // background-color: #ccc;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.bidHallBox {
  padding-bottom: 20px;
  box-sizing: border-box;
  .banner {
    width: 100%;
    height: 200px;
    background: url("../../assets/images/workSpace/banner.png") center/cover
      no-repeat;
    .bannerTit {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      font-weight: 600;
      font-size: 28px;
      color: #ffffff;
      position: relative;
      span {
        position: absolute;
        bottom: 36px;
      }
    }
  }

  .content {
    width: 1200px;
    margin: 0 auto;
    .registeredBox {
      .title {
        font-weight: 500;
        font-size: 28px;
        color: #000000d9;
        margin-top: 40px;
        padding-bottom: 39px;
        box-sizing: border-box;
        border-bottom: 1px solid #e0e0e0;
      }
      .tableBox {
        margin-top: 39px;
      }
    }

    .auctionProgress {
      margin-top: 121px;
      .title {
        font-weight: 500;
        font-size: 28px;
        color: #000000d9;
        padding-bottom: 39px;
        box-sizing: border-box;
        border-bottom: 1px solid #e0e0e0;
      }
      .bidBox {
        display: flex;
        margin-top: 43px;
        .left {
          width: 680px;
          .img {
            // width: 100%;
            // height: 100%;
          }
          .steps {
            height: 172px;
            margin-top: 56px;
            border: 1px solid #f0f0f0;
            padding: 10px 20px;
            box-sizing: border-box;
            .stepTitle {
              font-size: 16px;
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
              span {
                &:last-child {
                  font-weight: 400;
                  font-size: 16px;
                  color: #0dac65;
                  cursor: pointer;
                }
              }
              .tip {
                display: flex;
                align-items: center;
              }
            }
          }
        }
        .right {
          width: calc(100% - 680px);
          padding-left: 30px;
          box-sizing: border-box;
          .statusAndTitle {
            width: 100%;
            height: 72px;
            // padding: 0 20px;
            box-sizing: border-box;
            position: relative;
            .status,
            .status1 {
              position: absolute;
              top: 4px;
              width: 84px;
              height: 24px;
              background-color: rgba(255, 127, 0, 0.1);
              font-weight: 600;
              font-size: 14px;
              color: #ff7f00;
              text-align: center;
              line-height: 24px;
            }
            .status1 {
              background-color: rgba(13, 172, 101, 0.1);
              color: #0dac65;
            }
            .tit::before {
              content: "";
              display: inline-block;
              width: 94px;
              height: 1px;
              position: static;
            }
            .tit {
              line-height: 1.5; /* 调整行高，可以根据需要调整这个值 */
              // white-space: nowrap; /* 不换行 */
              overflow: hidden; /* 隐藏超出部分 */
              text-overflow: ellipsis; /* 用省略号表示超出部分 */
              max-width: 100%; /* 限制宽度不超过父元素宽度 */
              display: -webkit-box;
              -webkit-line-clamp: 2; /* 显示的行数 */
              -webkit-box-orient: vertical;
              font-weight: 500;
              font-size: 22px;
              color: #000000d9;
            }
          }
          .pubTit {
            font-weight: 400;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.4);
            letter-spacing: 7px;
            margin-right: 6px;
          }
          .price {
            padding-bottom: 24px;
            box-sizing: border-box;
            border-bottom: 1px solid #e0e0e0;

            .pubTit1 {
              font-weight: 400;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.4);
              margin-right: 13px;
            }
            .pubCon {
              font-weight: 400;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.8);
            }
            .enterpriseName {
              margin-top: 10px;
            }
            .deposit {
              margin-top: 8px;
              display: flex;
              .left1 {
                width: 50%;
                // margin-right: 100px;
              }
              .right1 {
                .pubTit {
                  color: red;
                  letter-spacing: 0px;
                }
              }
            }
            .start {
              margin-top: 8px;
              display: flex;
              .left1 {
                width: 50%;
                // margin-right: 100px;
              }
            }
            .evaluationPrice {
              margin-top: 8px;
              display: flex;
              .left1 {
                width: 50%;
                // margin-right: 100px;
              }
            }
          }

          .countdown {
            padding-top: 29px;
            box-sizing: border-box;
            .end {
              .time1 {
                font-weight: 400;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.4);
              }
              .time {
                font-weight: 600;
                font-size: 22px;
                color: rgba(0, 0, 0, 0.85);
              }
            }

            .currentPrice {
              margin-top: 8px;
              display: flex;
              .priceBox {
                width: 70%;
                height: 30px;

                // margin-right: 100px;
                .price2 {
                  font-weight: 600;
                  color: rgba(255, 127, 0, 1);
                  .price1 {
                    font-size: 22px;
                    // font-weight: 600;
                  }
                }
              }
              .personBox {
                line-height: 30px;
                .num {
                  font-weight: 600;
                  font-size: 14px;
                  color: #000000;
                }
              }
            }
          }
          .offer1 {
            margin-top: 16px;
            height: 110px;
            background: rgba(13, 172, 101, 0.1);
            padding: 16px 24px;
            box-sizing: border-box;
            .success {
              font-weight: 500;
              font-size: 22px;
              color: #0dac65;
              text-align: center;
              margin-top: 9px;
            }
            .tip {
              font-size: 12px;
              font-weight: 400;
              text-align: center;
              margin-top: 10px;
            }
          }
          .offer {
            margin-top: 16px;
            // height: 110px;
            background: #f5f5f5;
            padding: 16px 20px;
            box-sizing: border-box;
            .fail {
              font-weight: 500;
              font-size: 22px;
              color: rgba(0, 0, 0, 0.25);
              text-align: center;
              margin-top: 9px;
            }
            .tipFail {
              font-size: 12px;
              font-weight: 400;
              text-align: center;
              margin-top: 10px;
              color: rgba(0, 0, 0, 0.3);
            }
            .wz {
              font-weight: 400;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.85);
              margin-right: 20px;
            }
            .bidNum {
              font-weight: 600;
              font-size: 14px;
              color: #0dac65;
            }
            .hs {
              font-weight: 500;
              font-size: 22px;
              color: rgba(0, 0, 0, 0.4);
              line-height: 78px;
              text-align: center;
            }
            .top {
              height: 30px;
              display: flex;
              align-items: flex-end;
              .my {
                width: 50%;
                .price3 {
                  font-weight: 400;
                  color: rgba(13, 172, 101, 1);
                  .price4 {
                    font-size: 20px;
                  }
                }
              }
              .bidNumber {
                .bidNum {
                  font-weight: 600;
                  font-size: 14px;
                  color: #0dac65;
                }
              }
            }
            .btnClass {
              height: 80px;
              // display: flex;
              // align-items: flex-end;
              margin-top: 8px;
              .btn {
                // width: 45%;
                // .btn1 {
                //   width: 200px;
                //   background: #0dac65;
                //   font-weight: 400;
                //   font-size: 16px;
                //   color: #ffffff;
                //   text-align: center;
                //   line-height: 40px;
                //   cursor: pointer;
                // }
                margin-top: 8px;
              }
              .priceBtn {
                
                width: 100%;
                // margin-left: 10px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
              }
            }
          }
          .offerPrice{
            // height:155px;
          }
          .operationRecord {
            margin-top: 31px;
            .recordTit {
              font-weight: 500;
              font-size: 16px;
              color: rgba(0, 0, 0, 0.85);
            }
            .recordCon {
              height: 158px;
              background: #f5f5f5;
              padding: 12px 24px;
              box-sizing: border-box;
              margin-top: 7px;
              .conList {
                // width: 50%;
                height: 100%;
                // margin: 0 auto;
                overflow: auto;

                .list {
                  height: 30px;
                  line-height: 30px;
                  span {
                    display: inline-block;
                    font-weight: 400;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.6);
                    &:first-child {
                      margin-right: 10px;
                    }
                    &:last-child {
                      // width: 50%;
                      // text-align: left;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .listBox {
      width: 1200px;
      margin: 18px auto;
      .bdjs {
        display: flex;
        align-items: center;
        flex-flow: column;
      }
    }
  }
}
</style>
